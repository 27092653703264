<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  created() {
    this.$router.push(`profile/${this.user.slug}`)
  },
}
</script>
